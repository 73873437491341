#aboutUs {
    padding-top: 50px;
}

.aboutUs_dataCont,
.aboutUs_missionVision,
.aboutUs_principalValues_cont {
    margin: auto;
    padding: 0 15px;
    max-width: 1200px;
}

.aboutUs_header {
    margin-top: 60px;
    line-height: 1.22em;
    margin-bottom: 10px;
    font-weight: 600;
    text-align: center;
    position: relative;
    padding-bottom: 15px;
    color: #222;
}

.aboutUs_header::after {
    content: '';
    height: 3px;
    width: 70px;
    background-color: #003969;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.aboutUs_header>span {
    text-align: center;
    margin-bottom: 20px;
    font-size: 17px;
    line-height: 1.2em;
    font-weight: 500;
    letter-spacing: 1px;
    display: block;
    color: #003969;
}

.aboutUs_dataCont {
    margin-top: 50px;
    display: flex;
    align-items: flex-start;
    padding-bottom: 35px;
}

.aboutUs_dataCont>img {
    width: 100%;
    max-width: 600px;
    height: 400px;
    margin-right: 25px;
}

.aboutUs_dataCont_content>p {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 27px;
}

.aboutUs_stripData {
    background-color: #003969;
}

.aboutUs_stripData_cont {
    max-width: 1200px;
    margin: auto;
    padding: 40px 25px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    color: #ffffffe6;
    font-weight: 400;
    text-align: center;
}

.aboutUs_stripData_cont>div>p:first-of-type {
    font-size: 64px;
}

.aboutUs_stripData_cont>div>p:last-of-type {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
}

.aboutUs_missionVision {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    margin-top: 75px;
    padding-bottom: 75px;
}

.aboutUs_mission,
.aboutUs_vission {
    text-align: center;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.21);
    border-radius: 3px;
    box-sizing: border-box;
    padding: 50px 25px;
    color: #333;
    height: 100%;
}

.aboutUs_mission>img,
.aboutUs_vission>img {
    height: 55px;
    width: 55px;
    display: block;
    margin: auto;
    margin-bottom: 10px;
}

.aboutUs_mission>h2,
.aboutUs_vission>h2 {
    font-size: 20px;
    font-weight: 600;
}

.aboutUs_mission>p,
.aboutUs_vission>p {
    font-size: 16px;
    font-weight: 600;
    margin-top: 23px;
}

.aboutUs_principalValues {
    background-color: #ffffff8c;
}

.aboutUs_principalValues_cont {
    padding: 75px 15px;
}

.aboutUs_principalValues_cont>h2 {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 20px;
}

.aboutUs_principalValues_dataCont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 20px;
    margin-left: 25px;
}

.aboutUs_principalValues_dataCont>div>h6 {
    font-size: 20px;
    font-weight: 600;
    position: relative;
    color: #111;
}

.aboutUs_principalValues_dataCont>div>h6::before {
    content: ' ';
    height: 6px;
    width: 6px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: block;
    background-color: #000;
    position: absolute;
    top: 50%;
    left: -10px;
}

.aboutUs_principalValues_dataCont>div>p {
    font-size: 16px;
    line-height: 27px;
    color: #000000a2;
}

@media (max-width: 1000px) {
    .aboutUs_dataCont>img {
        display: none;
    }

    .aboutUs_dataCont{ 
        margin-top: 20px
    }
    
    .aboutUs_dataCont {
        padding-bottom: 10px;
    }
}

@media (max-width: 768px) {
    .aboutUs_missionVision {
        grid-template-columns: 1fr;
        row-gap: 50px;
    }

    .aboutUs_header {
        font-size: 28px;
    }

    .aboutUs_principalValues_cont {
        padding: 40px 15px;
    }

    .aboutUs_stripData_cont {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 25px;
    }
}

@media (max-width: 500px) {
    .aboutUs_principalValues_dataCont {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 400px) {
    .aboutUs_header {
        font-size: 25px;
    }

    .aboutUs_principalValues_cont>h2 {
        font-size: 28px;
    }

    .aboutUs_stripData_cont>div>p:first-of-type {
        font-size: 40px;
    }
}