#home {
    padding-top: 75px;
}

.home_carouselImgCont {
    height: 550px;
    position: relative;
}

.home_carouselImgCont_backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1;
}

.home_carouselImgCont>img {
    height: 550px;
    object-fit: cover;
    object-position: top;
    width: 100%;
}

.home_carouselImgCont>h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 90%;
    font-size: 54px;
    line-height: 1.26em;
    font-weight: 400;
    font-family: "Poppins", serif;
    color: #fff;
    margin: 0;
    z-index: 2;
    user-select: none;
}

.home_specialisation {
    padding: 100px 15px;
    padding-bottom: 50px;
}

.home_specialisation>h2 {
    font-size: 45px;
    margin-bottom: 5px;
    font-weight: 600;
    text-align: center;
    position: relative;
    z-index: 1;
    color: #003969;
}

.home_specialisation>h2::after {
    content: 'Specialisation';
    font-size: 120px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    color: #003a6952;
    font-weight: 700;
}

.home_specialisation_borderBottom,
.home_services_borderBottom {
    height: 3px;
    width: 120px;
    background-color: #003969;
    display: block;
    margin: auto;
}

.home_specialisation_text {
    max-width: 1200px !important;
    margin: auto;
    margin-top: 34px;
    text-align: center;
    font-size: 16px;
    color: #888888;
}

.home_specialisation_iconCont {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 25px;
    grid-column-gap: 25px;
    max-width: 1150px;
    margin: auto;
    margin-top: 30px;
}

.home_specialisation_iconData {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.25s ease-in;
}

.home_specialisation_iconData_active {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}

.home_specialisation_iconData:hover {
    transform: scale(1.15);
}

.home_specialisation_icon {
    width: 100%;
    height: 62px;
    max-width: 62px;
    display: block;
    margin: auto;
}

.home_specialisation_iconData>p {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
}

.home_specialisation_iconData_read {
    max-width: 1150px;
    margin: auto;
    margin-top: 25px;
    display: flex;
}

.home_specialisation_iconData_read>img {
    height: 425px;
    object-fit: contain;
    margin-right: 20px;
    border-radius: 5px;
}

.home_specialisation_iconData_readData>h1 {
    color: #333;
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 20px;
}

.home_specialisation_iconData_readData>h4 {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 27px;
    color: #787878;
    font-weight: 400;
}

.home_specialisation_iconData_read_companyName_cont {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 5px;
    column-gap: 10px;
}

.home_specialisation_iconData_read_companyName {
    display: flex;
    align-items: flex-start;
    color: #222;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
}

.home_specialisation_iconData_read_companyName>svg {
    color: #003969;
    margin-right: 15px;
    font-size: 16px;
    margin-top: 5px;
}

.home_services {
    background-color: #003969;
    padding: 100px 15px;
    padding-bottom: 60px;
}

.home_services_cont {
    max-width: 1200px;
    margin: auto;
}

.home_services_cont>h2 {
    font-size: 36px;
    margin-bottom: 5px;
    font-weight: 600;
    position: relative;
    z-index: 1;
    color: #fff;
}

.home_services_cont>h2::after {
    content: 'Service';
    font-size: 120px;
    position: absolute;
    top: -69px;
    left: 0;
    z-index: 0;
    color: #ffffff16;
    font-weight: 700;
}

.home_services_borderBottom {
    background-color: #fff;
    margin: unset;
    width: 60px;
    margin-top: 15px;
    margin-bottom: 60px;
}

.home_services_sec1,
.home_services_sec2,
.home_services_sec3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    margin-top: 30px;
}

.home_services_sec3 {
    grid-template-columns: repeat(2, 1fr);
}

.home_services_sec1>div>p,
.home_services_sec2>div>p,
.home_services_sec3>div>p {
    font-size: 72px;
    line-height: 1em;
    font-weight: 600;
    font-family: "Poppins", serif;
    margin-bottom: 10px;
    color: #ffffffdb;
}


.home_services_sec1>div>h4,
.home_services_sec2>div>h4,
.home_services_sec3>div>h4 {
    color: #eee;
}

.home_marketCoverage {
    background: url('../../img/bg-map.png') no-repeat center/cover;
    max-width: 1200px;
    padding: 75px 15px;
    margin: auto;
    display: flex;
    align-items: center;
}

.home_marketCoverage>div>h5 {
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 45px;
    color: #003969;
}

.home_marketCoverage>div>p {
    font-size: 16.5px;
    color: #444;
    font-weight: 500;
    margin-top: 15px;
}

.home_marketCoverage>div>p:first-of-type {
    margin-top: 0;
}

.home_marketCoverage>img {
    height: 500px;
    width: 500px;
    object-fit: contain;
    border-radius: 50%;
    margin-left: 30px;
}

.home_marketCoverage>div>div>button {
    background-color: #003969 !important;
    margin-top: 25px;
}

.homeIntro_modalCont {
    background-color: #f0f8ff;
    position: absolute;
    max-width: 950px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: 0;
    padding: 15px 20px;
    border-radius: 10px;
}

.homeIntro_modalCont>button {
    position: absolute;
    z-index: 1;
    color: #003969;
    border: 1px solid #003969;
    border-radius: 50%;
}

.homeIntro_modalCont>video {
    width: 100%;
    border: none;
    outline: none;
    border-radius: 10px;
}

@media (max-width: 1000px) {
    .home_specialisation_iconData_read {
        flex-direction: column;
    }

    .home_specialisation_iconData_read>img {
        margin-right: 0;
    }

    .home_specialisation_iconData_readData {
        max-width: 555px;
        margin: auto;
        text-align: center;
    }

    .home_specialisation_iconData_readData>h1 {
        margin-top: 10px;
        margin-bottom: 0;
    }

    .home_specialisation_iconData_read_companyName {
        text-align: left;
    }

    .homeIntro_modalCont {
        width: calc(100% - 65px);
    }
}

@media (max-width: 900px) {
    .home_specialisation {
        padding-top: 75px;
    }

    .home_specialisation>h2::after {
        font-size: 100px;
    }

    .home_marketCoverage>img {
        height: 300px;
        width: unset;
    }

    .home_carouselImgCont>h3 {
        font-size: 40px;
        width: 95%;
    }
}

@media (max-width: 768px) {
    .home_specialisation>h2::after {
        font-size: 75px;
    }

    .home_specialisation_iconCont {
        grid-template-columns: repeat(2, 1fr);
    }

    .home_specialisation_iconData:hover {
        transform: scale(1);
    }

    .homeIntro_modalCont>button {
        height: 25px;
        width: 25px;
    }

    .homeIntro_modalCont>button>svg {
        font-size: 15px;
    }
}

@media (max-width: 600px) {
    .home_specialisation {
        padding-top: 55px;
    }

    .home_specialisation>h2::after {
        font-size: 12vw;
    }

    .home_specialisation>h2 {
        font-size: 37px;
    }

    .home_services_sec1,
    .home_services_sec2,
    .home_services_sec3 {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 30px;
    }

    .home_marketCoverage {
        flex-direction: column;
        padding: 40px 15px;
        position: relative;
    }

    .home_marketCoverage>div>h5 {
        max-width: 400px;
        line-height: 55px;
        margin-bottom: 40px;
    }

    .home_marketCoverage>img {
        height: 150px;
        top: 15px;
        right: 15px;
        position: absolute;
    }

    .home_specialisation_iconData_read>img {
        height: unset;
    }
}

@media (max-width: 500px) {
    .home_services {
        padding-top: 70px;
    }

    .home_services_cont>h2::after {
        font-size: 24vw;
    }
}

@media (max-width: 450px) {
    .home_services_cont>h2 {
        font-size: 7vw;
    }

    .home_specialisation>h2 {
        font-size: 8vw;
    }

    .home_services_cont>h2::after {
        top: -50px;
    }

    .home_specialisation_iconData_read_companyName_cont {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 450px) {
    .home_specialisation_iconCont {
        grid-template-columns: repeat(1, 1fr);
    }

    .home_marketCoverage>img {
        height: 100px;
        margin: 0;
    }
}

@media (max-width: 350px) {
    .home_specialisation_iconData_readData>h4 {
        margin-bottom: 15px;
    }

    .home_services_borderBottom {
        margin-bottom: 30px;
    }

    .home_marketCoverage>img {
        display: none;
    }
}