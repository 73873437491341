#contact {
    padding-top: 75px;
}

.contact_gmapsCont {
    height: 50vh;
    width: 100%;
    position: relative;
}

.contact_gmapsCont_map {
    height: 100%;
    width: 100%;
}

.contact_gmapsCont .leaflet-control-attribution {
    display: none;
    opacity: 0;
    height: 0;
    width: 0;
}

.contact_gmapsCont_locateMe {
    position: absolute !important;
    right: 15px;
    bottom: 15px !important;
    bottom: 0;
}

.contact_gitws {
    max-width: 1200px;
    margin: auto;
    padding: 50px 10px;
    display: flex;
}

.contact_gitws_sec1 {
    max-width: 430px;
    padding-right: 50px;
}

.contact_gitws_sec2 {
    flex: 1;
}

.contact_gitws_title {
    line-height: 1.22em;
    font-weight: 600;
    font-size: 36px;
    color: #003969;
    position: relative;
    margin-bottom: 50px;
}

.contact_gitws_title::after {
    position: absolute;
    content: '';
    height: 3px;
    width: 53px;
    left: 0;
    bottom: -15px;
    background-color: #003969;
}

.contact_leafletMarkerIcon {
    background-color: transparent;
    object-fit: contain;
}

.contact_gitws_sec2>form>.MuiFormControl-root {
    margin-bottom: 20px;
} 

.contact_gitws_sec2>form>button {
    background-color: #003969 !important;
    padding: 10px 20px;
    border-radius: 50px;
    display: block;
    margin-left: auto;
} 

@media (max-width: 800px) {
    .contact_gitws {
        display: block;
        padding: 50px 20px;
    }

    .contact_gitws_sec1 {
        padding: 0;
        margin: 0 auto 25px;
    }

    .contact_gitws_sec2 {
        max-width: 430px;
        margin: auto;
    }
}