.app {
    background-color: #f0f8ff;
    min-height: 100vh;
}

.app_fabIcon {
    background-color: #25d366 !important;
    position: fixed !important;
    bottom: 12px;
    right: 12px;
}

.app_fabIcon:hover {
    background-color: #05af43 !important;
}