.footer {
    width: 100%;
    position: relative;
    height: auto;
    background-color: #ffffff41;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.075);
}

.footer_cont {
    padding: 30px 15px;
    max-width: 1200px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
}

.footer_logo {
    height: 60px;
    padding-bottom: 15px;
}

.footer_col1>img {
    cursor: pointer;
}

.footer_col1>h2 {
    font-size: 13px;
    font-weight: 400;
    color: #333;
}

.footer_colHeading {
    color: #003969;
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 600;
    position: relative;
}

.footer_colHeading::after {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 2px;
    width: 30px;
    background-color: #003a6952;
}

.footer_col2,
.footer_col4 {
    justify-self: center;
}

.footer_col2>p,
.footer_col3>p,
.footer_col4>a {
    font-size: 16px;
    font-weight: 600;
    color: #444;
    margin-bottom: 10px;
    cursor: default;
}

.footer_col2>p,
.footer_col3>p {
    cursor: pointer;
}

.footer_col4>a {
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    text-decoration: none;
}

.footer_col4>a>svg {
    color: #003969;
    margin-right: 12px;
}

@media (max-width: 900px) {
    .footer_cont {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 20px;
    }

    .footer_col2,
    .footer_col4 {
        justify-self: start;
    }
}

@media (max-width: 768px) {
    .footer_cont {
        column-gap: 15px;
    }
}

@media (max-width: 576px) {
    .footer_cont {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
    }
}

@media (max-width: 576px) {
    .footer_col4>a {
        word-break: break-word;
    }
}