#navbar {
    background-color: transparent;
    z-index: 99;
    position: unset;
    position: fixed;
    width: 100%;
    transition: all 0.25s;
}

#navbar.sticky {
    background-color: #ffffffc1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

#navbar>section {
    display: flex;
    justify-content: space-between;
    padding: 0 100px;
    height: 75px;
    align-items: center;
    transition: .4s;
    max-width: 850px;
    margin: auto;
}

.navbar_logo {
    height: 65%;
    cursor: pointer;
}

#navbar .navlist {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
}

#navbar .menu_items {
    text-decoration: none;
    color: #003969;
    font-size: 16px;
    margin: 0;
    margin-right: 20px;
    font-weight: bold;
    transition: all 0.2s ease-in;
    position: relative;
    display: flex;
    align-items: center;
}

#navbar .menu_items:last-of-type {
    margin-right: 0;
}

#navbar .menu_items:hover {
    color: #003a69d4;
}

#navbar .menu_items_active::after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: #003969;
    display: block;
    position: absolute;
    bottom: 0;
}

.menu_items_submenu {
    color: #003969 !important;
    font-weight: 500 !important;
}

.log {
    color: white;
    background: rgb(14, 145, 206);
    height: 50px;
    display: flex;
    align-items: center;
    width: 80px;
    justify-content: center;
    border-radius: 5px;
}

.log:hover {
    background: orange;
    transition: .3s;
}

.fas {
    display: none;
}

.navbar_mobile_menu {
    display: none !important;
}

@media (max-width: 800px) {
    .navbar_mobile_menu {
        display: block !important;
        margin-left: auto;
        font-size: 30px !important;
    }

    .navbar_mobile_menu_closeIcon {
        color: #ddd;
    }

    #navbar>section {
        padding: 0 25px;
    }

    .navlist {
        background: #003a69;
        transform: translateX(100vw);
        position: absolute !important;
        top: 0;
        right: 0;
        flex-direction: column;
        padding: 0;
        display: block;
        list-style: none;
        width: 200px;
        height: calc(100vh - 20px);
        cursor: pointer;
        opacity: 0;
        transition: transform 1s, opacity 0.3s;
    }

    .navlist_active {
        background: #003a69;
        position: absolute;
        top: 0;
        right: 0;
        transition: .4s;
        display: block;
        width: 200px;
        height: calc(100vh - 20px);
        cursor: pointer;
        margin: 0;
        opacity: 1;
        padding: 10px;
        overflow-y: hidden;
    }

    #navbar .menu_items:hover {
        border: 0;
    }

    #navbar .menu_items {
        color: #fff !important;
        text-align: center;
        margin: 0 auto !important;
        margin-bottom: 15px !important;
        max-width: max-content;
    }

    #navbar .menu_items:last-of-type {
        margin-bottom: 10px !important;
    }

    #navbar .menu_items_active {
        color: #ddd !important;
    }
}

@media (max-width: 800px) {
    #navbar>section {
        padding: 0 15px;
    }

    .navbar_logo {
        height: 55%;
    }
}