#products {
    padding-top: 75px;
}

.products_cont {
    max-width: 1200px;
    padding: 0 10px;
    margin: auto;
}

.products_cont_title {
    text-align: center;
    margin-bottom: 20px;
    line-height: 1.2em;
    font-weight: 600;
    letter-spacing: 1px;
    display: block;
    color: #222;
    position: relative;
    padding-top: 15px;
}

.products_cont_title::after {
    content: '';
    height: 3px;
    width: 120px;
    background-color: rgba(0, 0, 0, 0.297);
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.products_productCont {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 25px;
    row-gap: 35px;
    padding: 25px 0 50px;
}

.products_product {
    cursor: pointer;
    text-align: center;
    transition: all 0.25s ease-in;
}

.products_product:hover {
    transform: scale(1.05);
}

.products_product>img {
    width: 100%;
    border-radius: 3px;
    height: 290px;
    background-color: #fff;
    object-fit: contain;
    transition: all 0.5s ease-in;
}

.products_product>h1 {
    font-size: 20px;
    font-weight: 600;
}

.products_product>h2 {
    font-size: 18px;
    font-weight: 500;
}

.products_product>h3 {
    font-size: 14px;
    font-weight: 500;
    color: rgb(79, 79, 79);
}

.products_productDialog_title {
    display: flex;
    align-items: center;
}

.products_productDialog_title>button {
    margin-right: 10px;
}

.products_productDialog_title>p {
    font-size: 25px;
    font-weight: 600;
}

.products_productDialog_content>img {
    float: left;
    height: 100%;
    max-height: 375px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    max-width: 450px;
}

.products_productDialog_content>p {
    text-align: justify;
    white-space: pre-line;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.products_productDialog_content_headerWithText {
    margin-bottom: 10px;
    color: #000;
}

.products_productDialog_content_headerWithText>h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 35px;
}

.products_productDialog_content_headerWithText>h2>span {
    font-size: 16px;
    font-weight: 500;
}

@media (max-width: 1000px) {
    .products_productCont {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 800px) {
    .products_productCont {
        grid-template-columns: repeat(2, 1fr);
        max-width: 600px;
        margin: auto;
    }

    .products_productDialog_content>img {
        float: none;
        margin: 0 auto;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin-bottom: 20px;
    }
}

@media (max-width: 500px) {
    .products_productCont {
        grid-template-columns: 1fr;
        max-width: 350px;
        margin: auto;
    }

    .products_product>img {
        height: 400px;
    }

    .products_productDialog_title>p {
        font-size: 20px;
    }
}

@media (max-width: 400px) {
    .products_productDialog_title {
        padding: 16px !important;
    }

    .products_productDialog_title>p {
        font-size: 18px;
        font-weight: 700;
    }

    .products_productDialog_title>button {
        margin: 0;
        padding-left: 0;
    }
}